<template>
  <div v-if="data.length">
    <section>
      <b-tabs type="is-boxed"
              multiline
              size="default">
        <b-tab-item label="Interpretación">

          <div class="column is-12 is-multiline">
            <div class="columns is-12 column is-multiline">
              <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 16px;">
                <b-tooltip label="Guardar">
                  <b-button icon-left="save" type="is-primary" tag="a"
                            v-if="data[selected]"
                            @click="onSave(data[selected])">

                  </b-button>
                  <b-button icon-left="save" type="is-primary" tag="a"
                            v-else>

                  </b-button>
                </b-tooltip>
                <b-tooltip label="Ver pdf">
                  <b-button icon-left="eye" type="is-primary" tag="a"
                            v-if="data[selected]"
                            :href="data[selected].showPdfUrl"
                            target="_blank"></b-button>
                  <b-button icon-left="eye" type="is-primary" tag="a"
                            v-else
                            target="_blank"></b-button>
                </b-tooltip>
                <b-tooltip label="Volver a generar pdf">
                  <b-button icon-left="arrows-rotate" type="is-primary"
                            v-if="data[selected]"
                            @click=handleClick(data[selected])
                  ></b-button>
                  <b-button icon-left="arrows-rotate" type="is-primary"
                            v-else></b-button>
                </b-tooltip>
                <b-tooltip label="Ver Machote(estudio)">
                  <b-button icon-left="paste" type="is-primary"
                            v-if="data[selected]"
                            @click="onSearch(data[selected])">

                  </b-button>
                  <b-button icon-left="paste" type="is-primary"
                            v-else></b-button>
                </b-tooltip>
                <b-tooltip label="Catalogo de Machotes">
                  <b-button icon-left="file-prescription" type="is-primary"
                            v-if="data[selected]"
                            @click="showTemplateCatalog=true">
                  </b-button>
                  <b-button icon-left="file-prescription" type="is-primary"
                            v-else></b-button>
                </b-tooltip>

                <b-checkbox @input="showModal=true">Firma</b-checkbox>
                <b-checkbox>2aFirma</b-checkbox>
                <b-switch size="is-medium"
                          v-model="showMinas">¿Resultado Minas Lumbar?
                </b-switch>
              </div>
            </div>
          </div>
          <div class="columns is-multiline is-gapless">
            <div class="column is-2"
                 style="border-right: 2px #dbdbdb solid">
              <div class="column is-12">
                <div class="field is-size-6" style="margin-bottom: 1em">
                  <div class="is-divider" data-content="Tipo"></div>
                  <span class="is-pulled-left" v-if="data[selected]">Capturó: {{ data[selected].captureUser }}</span>
                  <span v-else>Capturó:</span><br>
                  <span class="is-pulled-left" v-if="data[selected]">Fecha: {{ data[selected].captureDate }}</span>
                  <span v-else>Fecha:</span><br>
                  <span class="is-pulled-left" v-if="data[selected]">Firmó: {{ data[selected].sign }}</span>
                  <span v-else>Firmó:</span><br>
                  <b-field>
                    <b-select
                        v-model="selected">
                      <option v-for="(row, index) in data" :key="row.index" :value="index">{{ row.type }}</option>
                    </b-select>
                  </b-field>
                </div>
              </div>
            </div>

            <div class="column is-10">
              <div class="columns is-12 column is-multiline">
                <div class="column is-12">
                  <div class="is-divider" data-content="Interpretación"></div>
                </div>

                <div class="column is-12">
                  <b-field label="Encabezado"
                           label-position="on-border">
                    <b-input v-model="data[selected].header" v-if="data[selected]" type="text" required
                             validation-message="Por favor llena este campo"></b-input>
                    <b-input v-else></b-input>
                  </b-field>
                </div>


                <div class="column is-12">
                  <b-field label="Motivo del Estudio"
                           label-position="on-border">
                    <b-input v-model="data[selected].reason" v-if="data[selected]" type="text" required
                             validation-message="Por favor llena este campo"></b-input>
                    <b-input v-else></b-input>
                  </b-field>
                </div>

                <div class="column is-12">
                  <b-field label="Metodología">
                    <b-input
                        maxlength="65535" type="textarea"
                        v-model="data[selected].methodology"
                        v-if="data[selected]"
                        required
                        validation-message="Por favor llena este campo"
                    />
                    <b-input v-else></b-input>
                  </b-field>
                </div>

                <div class="column is-12">
                  <b-field label="Hallazgos">
                    <b-input
                        maxlength="65535" type="textarea"
                        v-model="data[selected].findings"
                        v-if="data[selected]"
                        required
                        validation-message="Por favor llena este campo"
                    />
                    <b-input v-else></b-input>
                  </b-field>
                </div>
              </div>
            </div>

          </div>
          <div class="column is-12 has-background-white is-multiline">
            <div class="columns is-12 column is-multiline">
              <div class="column is-6">
                <b-field label="Conclusiones">
                  <b-input
                      maxlength="65535" type="textarea"
                      v-model="data[selected].conclusion"
                      v-if="data[selected]"
                      required
                      validation-message="Por favor llena este campo"
                  />
                  <b-input v-else></b-input>
                </b-field>
              </div>
              <div class="column is-6">
                <b-field label="Recomendaciones">
                  <b-input
                      maxlength="65535" type="textarea"
                      v-model="data[selected].recommendations"
                      v-if="data[selected]"
                      required
                      validation-message="Por favor llena este campo"
                  />
                  <b-input v-else></b-input>
                </b-field>
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item :visible="showMinas" label="Datos para Minas">
          <div class="column is-12 has-background-white is-multiline">
            <div class="columns is-12 is-multiline is-gapless">
              <div class="column is-6">
                <span class="text-green">FALTA CIERRE DE APOFISIS ESPINOSA: </span>
                <div class="block">
                  <b-radio v-model="numbers[0]"
                           name="val1"
                           native-value="1">
                    Si
                  </b-radio>
                  <b-radio v-model="numbers[0]"
                           name="val1"
                           native-value="0">
                    No
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-green">MEGAAPOFISIS TRANSVERSA: </span>
                <div class="block">
                  <b-radio v-model="numbers[1]"
                           name="val2"
                           native-value="1">
                    Si
                  </b-radio>
                  <b-radio v-model="numbers[1]"
                           name="val2"
                           native-value="0">
                    No
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-green">FUSION VERTEBRAL: </span>
                <div class="block">
                  <b-radio v-model="numbers[2]"
                           name="val3"
                           native-value="3">
                    Si
                  </b-radio>
                  <b-radio v-model="numbers[2]"
                           name="val3"
                           native-value="0">
                    No
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-green">HEMIVERTEBRA: </span>
                <div class="block">
                  <b-radio v-model="numbers[3]"
                           name="val4"
                           native-value="3">
                    Si
                  </b-radio>
                  <b-radio v-model="numbers[3]"
                           name="val4"
                           native-value="0">
                    No
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-green">CALCIFICACIÓN DE LIGAMENTOS: </span>
                <div class="block">
                  <b-radio v-model="numbers[4]"
                           name="val5"
                           native-value="3">
                    Si
                  </b-radio>
                  <b-radio v-model="numbers[4]"
                           name="val5"
                           native-value="0">
                    No
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-green">OSTEOFITOS MARGINALES (ESPONDILOARTROSIS): </span>
                <div class="block">
                  <b-radio v-model="numbers[5]"
                           name="val6"
                           native-value="3">
                    Si
                  </b-radio>
                  <b-radio v-model="numbers[5]"
                           name="val6"
                           native-value="0">
                    No
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-green">DISMINUCIÓN DE LA LORDOSIS: </span>
                <div class="block">
                  <div class="column is-4">
                    <b-input></b-input>
                  </div>
                  <b-radio v-model="numbers[6]"
                           name="val7"
                           native-value="3">
                    Si < 40
                  </b-radio>
                  <b-radio v-model="numbers[6]"
                           name="val7"
                           native-value="0">
                    No >= 40
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-green">ESPINA BIFIDA OCULTA L1-L5: </span>
                <div class="block">
                  <b-radio v-model="numbers[7]"
                           name="val8"
                           native-value="5">
                    Si < 40
                  </b-radio>
                  <b-radio v-model="numbers[7]"
                           name="val8"
                           native-value="0">
                    No >= 40
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-green">AUMENTO DE LA LORDOSIS: </span>
                <div class="block">
                  <div class="column is-4">
                    <b-input></b-input>
                  </div>
                  <b-radio v-model="numbers[8]"
                           name="val9"
                           native-value="5">
                    Si > 80
                  </b-radio>
                  <b-radio v-model="numbers[8]"
                           name="val9"
                           native-value="0">
                    No <= 80
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-green">SACRALIZACION DE APOFISIS TRANSVERSA: </span>
                <div class="block">
                  <div class="column is-4">
                    <b-input></b-input>
                  </div>
                  <b-radio v-model="numbers[9]"
                           name="val10"
                           native-value="5">
                    Si
                  </b-radio>
                  <b-radio v-model="numbers[9]"
                           name="val10"
                           native-value="0">
                    No
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-green">ESPONDILOSIS (DISCOPATÍA): </span>
                <div class="block">
                  <b-radio v-model="numbers[10]"
                           name="val11"
                           native-value="3">
                    Si
                  </b-radio>
                  <b-radio v-model="numbers[10]"
                           name="val11"
                           native-value="0">
                    No
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-yellow">ESPONDILOSIS (FRACTURA): </span>
                <div class="block">
                  <b-radio v-model="numbers[11]"
                           name="val12"
                           native-value="5">
                    Si
                  </b-radio>
                  <b-radio v-model="numbers[11]"
                           name="val12"
                           native-value="0">
                    No
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-yellow">ANGULO DE FERGUSON > 48: </span>
                <div class="block">
                  <div class="column is-4">
                    <b-input></b-input>
                  </div>
                  <b-radio v-model="numbers[12]"
                           name="val13"
                           native-value="5">
                    Si > 48
                  </b-radio>
                  <b-radio v-model="numbers[12]"
                           name="val13"
                           native-value="0">
                    No <= 48
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-yellow">LINEA DE CARGA L-3: </span>
                <div class="block">
                  <b-radio v-model="numbers[13]"
                           name="val14"
                           native-value="0">
                    Media
                  </b-radio>
                  <b-radio v-model="numbers[13]"
                           name="val14"
                           native-value="5">
                    Borde Ant. o Post
                  </b-radio>
                  <b-radio v-model="numbers[13]"
                           name="val14"
                           native-value="10">
                    Fuera Sacro
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-yellow">ESCOLIOSIS: </span>
                <div class="block">
                  <b-radio v-model="numbers[14]"
                           name="val15"
                           native-value="0">
                    No presenta
                  </b-radio>
                  <b-radio v-model="numbers[14]"
                           name="val15"
                           native-value="5">
                    1° a 10°
                  </b-radio>
                  <b-radio v-model="numbers[14]"
                           name="val15"
                           native-value="10">
                    > 10°
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-yellow">ROTOESCOLIOSIS: </span>
                <div class="block">
                  <b-radio v-model="numbers[15]"
                           name="val16"
                           native-value="5">
                    Si
                  </b-radio>
                  <b-radio v-model="numbers[15]"
                           name="val16"
                           native-value="0">
                    No
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-yellow">BASC. PELVICO IZQUIERDO: </span>
                <div class="block">
                  <div class="column is-4">
                    <b-input></b-input>
                  </div>
                  <b-radio v-model="numbers[16]"
                           name="val17"
                           native-value="0">
                    No Presenta
                  </b-radio>
                  <b-radio v-model="numbers[16]"
                           name="val17"
                           native-value="5">
                    Hasta 10mm
                  </b-radio>
                  <b-radio v-model="numbers[16]"
                           name="val17"
                           native-value="10">
                    > 10mm
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-yellow">BASC. PELVICO DERECHO: </span>
                <div class="block">
                  <div class="column is-4">
                    <b-input></b-input>
                  </div>
                  <b-radio v-model="numbers[17]"
                           name="val18"
                           native-value="0">
                    No Presenta
                  </b-radio>
                  <b-radio v-model="numbers[17]"
                           name="val18"
                           native-value="5">
                    Hasta 10mm
                  </b-radio>
                  <b-radio v-model="numbers[17]"
                           name="val18"
                           native-value="10">
                    > 10mm
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-red">DISMINUCION DE ESPACIO INTERVERTEBRAL <br> POSTERIOR: </span>
                <div class="block">
                  <b-radio v-model="numbers[18]"
                           name="val19"
                           native-value="10">
                    Si
                  </b-radio>
                  <b-radio v-model="numbers[18]"
                           name="val19"
                           native-value="0">
                    No
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-red">DISMINUCION DEL ESPACIO INTERVERTEBRAL: </span>
                <div class="block">
                  <b-radio v-model="numbers[19]"
                           name="val20"
                           native-value="10">
                    Si
                  </b-radio>
                  <b-radio v-model="numbers[19]"
                           name="val20"
                           native-value="0">
                    No
                  </b-radio>
                </div>
              </div>
              <div class="column is-6">
                <span class="text-red">ESPONDILOLISTESIS: </span>
                <div class="block">
                  <b-radio v-model="numbers[20]"
                           name="val21"
                           native-value="10">
                    Si
                  </b-radio>
                  <b-radio v-model="numbers[20]"
                           name="val21"
                           native-value="0">
                    No
                  </b-radio>
                </div>
              </div>
              <div class="column is-12">
                <b-message type="is-info">

                  <p><span class="text-green"> Minimo <= 10 <br></span>
                    <span class="text-yellow"> Moderado 11-24 <br></span>
                    <span class="text-yellow"> Medio 25-35 <br></span>
                    <span class="text-red"> Alto > 36</span></p>
                </b-message>
              </div>
              <div class="column is-12">
                <span><strong>TOTAL: {{ totalSum }}</strong><br></span>
                <div v-html="grade"></div>
              </div>
            </div>
          </div>

        </b-tab-item>
      </b-tabs>
    </section>

    <b-modal v-model="showModal">
      <sign-xray-modal :data="dataXray"/>
    </b-modal>
    <b-modal :active.sync="showModal">
      <xray-doc-template-study
          :data="data2"
          @selecttemplate="onSelectTemplate"
          />
    </b-modal>
    <b-modal v-model="showTemplateCatalog">
      <xray-doc-template-catalog/>
    </b-modal>
  </div>

</template>

<script>
import SignXrayModal from "@/components/medical-specialties/SignXrayModal";
import xrayDocTemplateStudy from "@/components/medical-specialties/XrayDocTemplateStudy";
import xrayDocTemplateCatalog from "@/components/medical-specialties/XrayDocTemplateCatalog";
export default {
  name: "Xray",
  components: {
    SignXrayModal,
    xrayDocTemplateStudy,
    xrayDocTemplateCatalog,
  },

  data() {
    return {
      showMinas: false,
      numbers: [],
      selected: null,
      showPdf: false,
      showModal: false,
      dataXray: "Dato desde el padre",
      data2: [],
      showTemplateCatalog: false,
    }
  },
  props: {
    data: Array,
  },
  computed: {
    totalSum() {
      return this.numbers.reduce((total, value) => total + (parseInt(value) || 0), 0);
    },
    grade() {
      if (this.totalSum <= 10) {
        return '<span style="color: green"> Minimo <= 10 <br></span>'
      } else if (this.totalSum >= 11 && this.totalSum <= 24) {
        return '<span style="color: goldenrod"> Moderado 11-24 <br></span>'
      } else if (this.totalSum >= 25 && this.totalSum <= 35) {
        return '<span style="color: goldenrod"> Medio 25-35 <br></span>'
      } else if (this.totalSum >= 36) {
        return '<span style="color: crimson"> Alto > 36</span>'
      }
    }
  },
  methods: {
    runAction(item) {
      this.$loader.show();
      fetch(item.generatePdfUrl).catch(error => {
        this.$alfalab.showError(error);
      }).finally(() => {
        this.$loader.hide();
      })
    },

    handleClick(item) {
      this.$loader.show();
      console.log(item.generatePdfUrl);
      this.$http.get(item.generatePdfUrl).then(() => {
        this.$toast.success('Acción realizada con éxito');
      }).catch(error => {
        this.$alfalab.showError(error);
      }).finally(() => {
        this.$loader.hide();
      })
    },

    onSave(item) {
      this.loading = true;
      this.$buefy.dialog.confirm({
        title: 'Guardar interpretación',
        message: '¿Estás seguro de guardar los datos?',
        confirmText: 'Si, guardar',
        cancelText: 'No, cancelar',
        onConfirm: () => {
          this.$loader.show();
          this.$http.post(`/medical-specialties/update-xray/${item.ordenNew}/${item.type}`, {
            test: 'tests',
            header: item.header,
            findings: item.findings,
            conclusion: item.conclusion,
            recommendations: item.recommendations,
            reason: item.reason,
            methodology: item.methodology
          }).then(({data}) => {
            console.log(data);
            this.$toast.success('Datos guardados correctamente');
          }).catch(errors => {
            this.$alfalab.showError(errors);
          }).finally(() => {
            this.$loader.hide();
          })
        }
      })
    },
    onSearch(item) {
      this.$loader.show();
      console.log(item.type)
      const params = {};
      this.$http.get(`/medical-specialties/template-xray-study/${item.type}`, {params}).then(async ({data}) => {
        //console.log(data.data);
        this.data2 = data.data;
        this.showModal = true;
      }).catch(errors => {
        this.$toast.error('Error');
      }).finally(() => {
        this.loading = false;
        this.$loader.hide();
      })
    },
    onSelectTemplate(data) {
      //console.log(data.header);
      console.log('enviando');
      this.$emit('selecttemplate', {data, selected: this.selected});
    },
  }
}
</script>

<style scoped>
.text-green {
  color: green;
}

.text-yellow {
  color: goldenrod;
}

.text-red {
  color: crimson;
}

.lapi__iframe {
  width: 100%;
  height: 80vh;
}
</style>