<template>

  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Firmando</p>
      <button
        type="button"
        class="delete"
        @click="$parent.close()">
      </button>
    </header>
    <section class="modal-card-body">
      <p>Favor de introducir Clave de Usuario y Contraseña</p><br>
      <span>{{ data }}</span>
      <b-field label="Clave"
                label-position="on-border">
        <b-input type="text"
                  required
                  validation-message="Por favor llena este campo"></b-input>
      </b-field>
      <b-field label="Contraseña"
                label-position="on-border">
        <b-input type="text"
                  required
                  validation-message="Por favor llena este campo"></b-input>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <b-button native-type="button" type="is-primary" @click="onTest()">Firmar</b-button>
      <b-button native-type="button" type="is-primary" @click="$parent.close()">Cancelar</b-button>
    </footer>
  </div>

</template>

<script>
export default {
  name: "SignXrayModal",
  props: {
    data: String
  },
  methods: {
    onTest(){
      console.log(data);
    },
    onSign() {
      this.loading = true;
      this.$buefy.dialog.confirm({
        title: 'Firmar',
        message: '¿Estás seguro de firmar el resultado?',
        confirmText: 'Si, firmar',
        cancelText: 'No, cancelar',
        onConfirm: () => {
          this.$loader.show();
          this.$http.post(`/medical-specialties/sign/{order}`, {
            test: 'test'
          }).then(({data}) => {
            console.log(data);
            this.$toast.success('Resultado firmado correctamente');
          }).catch(errors => {
            this.$alfalab.showError(errors);
          }).finally(() => {
            this.$loader.hide();
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>