<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p>Guardando</p>
    </header>
    <section class="modal-card-body">
      <p>Favor de introducir Clave de Usuario y Contraseña</p><br>
      <span></span>
      <b-field label="Clave"
               label-position="on-border">
        <b-input type="text"
                 required
                 validation-message="Por favor llena este campo"
                 v-model="user"></b-input>
      </b-field>
      <b-field label="Contraseña"
               label-position="on-border">
        <b-input type="password"
                 password-reveal
                 required
                 validation-message="Por favor llena este campo"
                 v-model="password"></b-input>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <b-button native-type="button" type="is-primary" @click="handleCheckUser">Guardar</b-button>
      <b-button native-type="button" type="is-primary" @click="$parent.close()">Cancelar</b-button>
    </footer>
  </div>
</template>

<script>
export default {
  name: "SaveModal",
  data() {
    return{
      user:'',
      password:''
    }
  },
  methods: {
    handleCheckUser(){
      this.$loader.show();
      const params={
        user: this.user,
        password: this.password
      };
      this.$http.post(`/medical-specialties/validate-user/`, params).then(({data}) => {
        this.$emit('capture', {
          status: true
        });
      }).catch(errors => {
        this.$buefy.dialog.alert("Datos incorrectos");
        this.$emit('fail');
      }).finally(() => {
        this.$loader.hide();
      })
    }
  }
}
</script>

<style scoped>

</style>