<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Firmando</p>
      <button
          type="button"
          class="delete"
          @click="$parent.close()">
      </button>
    </header>
    <section class="modal-card-body">
      <p>Favor de introducir Clave de Usuario y Contraseña</p><br>
      <b-field label="Clave"
               label-position="on-border">
        <b-input type="text"
                 required
                 validation-message="Por favor llena este campo"
                 v-model="clave"></b-input>
      </b-field>
      <b-field label="Contraseña"
               label-position="on-border">
        <b-input type="password"
                 password-reveal
                 required
                 validation-message="Por favor llena este campo"
                 v-model="password"></b-input>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <b-button native-type="button" type="is-primary" @click="handleCheckSign">Firmar</b-button>
      <b-button native-type="button" type="is-primary" @click="$emit('canceled');$parent.close()">Cancelar</b-button>
    </footer>
  </div>
</template>

<script>
export default {
  name: "SignModal",
  data() {
    return{
      clave:'',
      password:''
    }
  },
  methods: {
    handleCheckSign(){
      this.$loader.show();
      const params={
        clave: this.clave,
        password: this.password
      };
      this.$http.post(`/medical-specialties/validate-sign/`, params).then(({data}) => {
        //console.log('SignModal', data);
        this.$emit('capture', {
          status: true,
          sign: data.sign
        });
      }).catch(errors => {
        this.$buefy.dialog.alert("Datos incorrectos");
        this.$emit('fail');
        this.$loader.hide();
      }).finally(() => {
      })
    }
  }
}
</script>

<style scoped>

</style>