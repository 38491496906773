<template>

  <div class="column is-12 has-background-white is-multiline">
    <div class="columns is-12 column is-multiline">
      <div class="column is-12">
        <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 16px;">
          <b-tooltip label="Guardar">
            <b-button icon-left="save" type="is-primary"
                       @click="onSave"
                      :disabled="dataElectro.has_sign">

            </b-button>
          </b-tooltip>
          <b-tooltip label="Ver PDF">
            <b-button icon-left="eye" type="is-primary" tag="a"
                      :href="pdfUrl"
                      target="_blank"
                      :disabled="!dataElectro.has_sign"></b-button>
          </b-tooltip>
          <!--    <b-tooltip label="Volver a generar PDF">
                <b-button icon-left="arrows-rotate" type="is-primary">

                </b-button>
              </b-tooltip> -->

          <b-checkbox v-model="dataElectro.has_sign" @input="handleInputSign">Firma</b-checkbox>

        </div>
      </div>
      <div class="column is-8">
        <b-field>
          <b-field label="Doctor"
                   label-position="on-border">
            <b-input v-model="dataElectro.medical"></b-input>
          </b-field>
        </b-field>
      </div>
      <div class="column is-4">
        <b-field>
          <span>Firmado por:  {{ dataElectro.sign }}</span>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Ritmo"
                 label-position="on-border">
          <b-input v-model="dataElectro.rhythm" required
                   validation-message="Es necesario llenar este campo"></b-input>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Fc xMIN"
                 label-position="on-border">
          <b-input v-model="dataElectro.fc" required
                   validation-message="Es necesario llenar este campo"></b-input>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Pr s"
                 label-position="on-border">
          <b-input v-model="dataElectro.pr" type="number" step="0.01" required
                   validation-message="Es necesario llenar este campo"></b-input>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="QRS s"
                 label-position="on-border">
          <b-input v-model="dataElectro.qrs" type="number" step="0.01" required
                   validation-message="Es necesario llenar este campo"></b-input>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="AQRS °"
                 label-position="on-border">
          <b-input v-model="dataElectro.aqrs" required
                   validation-message="Es necesario llenar este campo"></b-input>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="QT s"
                 label-position="on-border">
          <b-input v-model="dataElectro.qt" type="number" step="0.01" required
                   validation-message="Es necesario llenar este campo"></b-input>
        </b-field>
      </div>
    </div>
    <b-field label="Interpretación">
      <b-input
          v-model="dataElectro.interpretation"
          maxlength="60000" type="textarea" required
          validation-message="Es necesario llenar este campo"/>
    </b-field>
    <b-field label="Diagnóstico">
      <b-input
          v-model="dataElectro.diagnostic"
          maxlength="60000" type="textarea" required
          validation-message="Es necesario llenar este campo"/>
    </b-field>
    <b-field label="Recomendaciones">
      <b-input
          v-model="dataElectro.recommendations"
          maxlength="60000" type="textarea" required
          validation-message="Es necesario llenar este campo"/>
    </b-field>

    <b-modal v-model="showModal">
      <sign-modal @capture="handleCapture" @fail="dataElectro.has_sign=false" @canceled="dataElectro.has_sign=false"></sign-modal>
    </b-modal>

    <b-modal v-model="showSaveModal">
      <save-modal @capture="onSave"></save-modal>
    </b-modal>
  </div>

</template>

<script>
import SignModal from "@/components/medical-specialties/SignModal";
import SaveModal from "@/components/medical-specialties/SaveModal";

export default {
  name: "Electrocardiogram",
  components: {
    SignModal,
    SaveModal
  },
  data() {
    return {
      showModal: false,
      showSaveModal: false,
    }
  },
  props: {
    dataElectro: Object,
    tabData: String,
    order: Object
  },
  computed: {
      pdfUrl(){
        if(this.dataElectro && this.dataElectro.showPdfUrl && this.order && this.order.ordenNew) {
          if (this.dataElectro.showPdfUrl.toString().indexOf(this.order.ordenNew) > -1) {
            return this.dataElectro.showPdfUrl;
          }
          return this.dataElectro.showPdfUrl + this.order.ordenNew;
        }
        return '';
      }
  },
  methods: {
    onSave() {
      const dataElectro = this.dataElectro;
      this.loading = true;
      //this.showSaveModal = false;
      this.$buefy.dialog.confirm({
        title: 'Guardar interpretación',
        message: '¿Estás seguro de guardar los datos?',
        confirmText: 'Si, guardar',
        cancelText: 'No, cancelar',
        onConfirm: () => {
          this.$loader.show();
          this.$http.post(`/medical-specialties/update-electro/${this.order.ordenNew}`, {
            medical: dataElectro.medical,
            rhythm: dataElectro.rhythm,
            fc: dataElectro.fc,
            pr: dataElectro.pr,
            qrs: dataElectro.qrs,
            aqrs: dataElectro.aqrs,
            qt: dataElectro.qt,
            interpretation: dataElectro.interpretation,
            diagnostic: dataElectro.diagnostic,
            recommendations: dataElectro.recommendations,
          }).then((data) => {
            console.log(data);
            this.$toast.success('Datos guardados correctamente');
          }).catch(errors => {
            this.$alfalab.showError(errors);
          }).finally(() => {
            this.$loader.hide();
          })
        }
      })
    },
    handleCapture(result) {
      this.$loader.show();
      this.$http.post(`/medical-specialties/sign-electro/${this.order.ordenNew}`, {
        sign: result.sign
      }).then(() => {
        this.dataElectro.has_sign = true;
        this.$buefy.dialog.alert("Firmado con éxito");
      }).catch(() => {
        this.dataElectro.has_sign = false;
      }).finally(() => {
        this.$loader.hide();
        this.showModal = false;
      })
    },
    handleInputSign(value) {
      //console.log(value);
      if (value) {
        this.showModal = true;
      } else {
        this.$buefy.dialog.confirm({
          title: 'Quitar Firma',
          message: '¿Seguro que quiere quitar la firma?',
          onConfirm: () => {
            this.$loader.show();
            this.$http.post(`/medical-specialties/sign-electro/${this.order.ordenNew}?remove=1`).then(() => {
              this.dataElectro.has_sign = false;
              this.$toast.success('Firma eliminada con éxito');
            }).finally(() => {
              this.$loader.hide();
            });
          },
          onCancel: () => {
            this.dataElectro.has_sign = true;
          }
        })
      }
    }
  },
}
</script>

<style scoped>

</style>