<template>
  <div>
    <div class="column is-12 has-background-white is-multiline">
      <div class="columns is-12 column is-multiline">
        <div class="column is-12">
          <b-field>
            <b-checkbox>Firmada</b-checkbox>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Antecedentes Familiares:">
            <b-input
                maxlength="200" type="textarea"/>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Antecedentes Personales:">
            <b-input
                maxlength="200" type="textarea"/>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Actividad Fisica:">
            <b-input
                maxlength="200" type="textarea"/>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Síntomas Generales:">
            <b-input
                maxlength="200" type="textarea"/>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Exploración Fisica:">
            <b-input
                maxlength="200" type="textarea"/>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Evaluacion Nutricional:">
            <b-input
                maxlength="200" type="textarea"/>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Evaluacion Oftalmologica:">
            <b-input
                maxlength="200" type="textarea"/>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Revisión Odontologica:">
            <b-input
                maxlength="200" type="textarea"/>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Revisión OtorrinoLaringología:">
            <b-input
                maxlength="200" type="textarea"/>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Revisión Proctologica:">
            <b-input
                maxlength="200" type="textarea"/>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Revisión Urológica:">
            <b-input
                maxlength="200" type="textarea"/>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Revisión Ginecológica:">
            <b-input
                maxlength="200" type="textarea"/>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Summary1"
}
</script>

<style scoped>

</style>