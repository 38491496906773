<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Búsqueda de machotes de rayosx</p>
    </header>
    <section class="modal-card-body">
      <b-table
          :data="data"
          :columns="columns"
          :selected.sync="selected">
      </b-table>
    </section>
  </div>
</template>

<script>
export default {
  name: "SelectXrayTemplateCatalogModal",
  props: {
    data: Array,
  },
  data(){
    return {
      selected:{},
      columns: [
        {
          field: 'id',
          label: 'ID',
          width: '100',
          numeric: true,
        },
        {
          field: 'first_name',
          label: 'First Name',
          searchable: true,
        },
        {
          field: 'last_name',
          label: 'Last Name',
          searchable: true,
        },
        {
          field: 'date',
          label: 'Date',
          centered: true
        },
        {
          field: 'gender',
          label: 'Gender',
        }
      ]
    }
  },
}
</script>

<style scoped>

</style>