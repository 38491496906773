<template>
  <div class="column is-12">
    <div class="field is-size-6" style="margin-bottom: 1em">
      <div class="is-divider" data-content="Histórico"></div>
  <!--    <div class="column is-2">
        <b-field>
          <b-checkbox>Misma empresa</b-checkbox>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field>
          <b-checkbox>Mismo estudio</b-checkbox>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field>
          <b-checkbox>Búsqueda por nombre</b-checkbox>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field>
          <b-checkbox>Fecha límite</b-checkbox>
        </b-field>
      </div> -->
      <div class="column is-12">
        <b-table
            striped
            bordered
            narrowed
            sticky-header
            :selected.sync="selected"
            @dblclick="onSelectOrder"
            :data="data"
            height="100%">
          <b-table-column field="date" label="Fecha" v-slot="props">{{ props.row.date }}</b-table-column>
          <b-table-column field="order" label="Orden" v-slot="props">{{ props.row.order }}</b-table-column>
        <!--  <b-table-column field="date" label="Fecha de nacimiento"></b-table-column>-->
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Historical",
  props: {
    data: Array
  },
  data() {
    return{
      selected: {},
    }
  },
  methods: {
    onSelectOrder(data){
      this.$emit('selectorder', {
        year: data.year,
        order: data.order
      })
    }
  },
}
</script>

<style scoped>

</style>