<template>
  <div class="modal-card" style="width: auto; height: 600px">
    <header class="modal-card-head">
      <p class="modal-card-title">Catálogo de machotes de rayos X</p>
    </header>
    <section class="modal-card-body">
        <div class="column is-12">
          <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 16px;">
            <b-tooltip label="Nuevo">
              <b-button icon-left="file" type="is-primary"></b-button>
            </b-tooltip>
            <b-tooltip label="Guardar">
              <b-button icon-left="save" type="is-primary"></b-button>
            </b-tooltip>
            <b-tooltip label="Primer machote">
              <b-button icon-left="circle-arrow-left" type="is-primary"></b-button>
            </b-tooltip>
            <b-tooltip label="Machote anterior">
              <b-button icon-left="arrow-left" type="is-primary"></b-button>
            </b-tooltip>
            <b-tooltip label="Machote siguiente">
              <b-button icon-left="arrow-right" type="is-primary"></b-button>
            </b-tooltip>
            <b-tooltip label="Último machote">
              <b-button icon-left="circle-arrow-right" type="is-primary"></b-button>
            </b-tooltip>
            <b-tooltip label="Buscar">
              <b-button icon-left="search" type="is-primary" @click="showModal=true"></b-button>
            </b-tooltip>
          </div>

          <div class="column is-6">
            <span>Clave</span>
          </div>
        </div>
      <b-modal v-model="showModal">
        <select-xray-template-catalog-modal :data="data" />
      </b-modal>
    </section>
  </div>
</template>

<script>
import selectXrayTemplateCatalogModal from "@/components/medical-specialties/SelectXrayTemplateCatalogModal";
export default {
  name: "XrayDocTemplateCatalog",
  components: {
    selectXrayTemplateCatalogModal
  },
  data() {
    return {
      showModal: false,
      selected: {},
      data: [
        { 'id': 1, 'first_name': 'Jesse', 'last_name': 'Simmons', 'date': '2016-10-15 13:43:27', 'gender': 'Male' },
        { 'id': 2, 'first_name': 'John', 'last_name': 'Jacobs', 'date': '2016-12-15 06:00:53', 'gender': 'Male' },
        { 'id': 3, 'first_name': 'Tina', 'last_name': 'Gilbert', 'date': '2016-04-26 06:26:28', 'gender': 'Female' },
        { 'id': 4, 'first_name': 'Clarence', 'last_name': 'Flores', 'date': '2016-04-10 10:28:46', 'gender': 'Male' },
        { 'id': 5, 'first_name': 'Anne', 'last_name': 'Lee', 'date': '2016-12-06 14:38:38', 'gender': 'Female' }
      ],
    }
  }

}
</script>

<style scoped>

</style>