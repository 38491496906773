<template>
  <div class="modal-card" style="width: auto; min-width: 300px">
    <header class="modal-card-head">
      <p class="modal-card-title" v-model=data>Búsqueda de machotes de rayos X estudio</p>
      <button
          type="button"
          class="delete"
          @click="$parent.close()"/>
    </header>
    <section class="modal-card-body">
      <div class="card-content">
        <b-table
          style=""
          :data="data"
          striped
          bordered
          height="100%"
          narrowed
          sticky-header
          paginated
          per-page="6"
          :selected.sync="selected"
          @dblclick="onSelectTemplate"
        >
          <b-table-column label="Clave" field="clave" v-slot="props">
            {{ props.row.header }}
          </b-table-column>
          <b-table-column label="Default" v-slot="props">
            {{ props.row.default }}
          </b-table-column>
          <b-table-column label="Machote" v-slot="props">
            {{ props.row.findings }}
          </b-table-column>
          <b-table-column label="Conclusion" v-slot="props">
            {{ props.row.conclusion }}
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                        icon="box-open"
                        size="is-large">
                  </b-icon>
                </p>
                <p>No se encuentran machotes para este estudio.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button native-type="button" type="is-primary" @click="$parent.close()">Cerrar</b-button>
    </footer>
  </div>
</template>

<script>
export default {
  name: "XrayDocTemplateStudy",
  props: {
    data: Array,
  },
  data(){
    return {
      selected: {},
    }
  },
  methods: {
    onSelectTemplate(data){
      this.$loader.show();
      //console.log(data.consecutive);
      const params = {};
      this.$http.get(`/medical-specialties/templateSelected/${data.consecutive}`,{params}).then(({data}) => {
        this.$emit('selecttemplate', data.data);
        //console.log('dataM', data.data);
      }).catch(errors => {
        this.$alfalab.showError(errors);
      }).finally(() => {
        this.$loader.hide();
        this.$parent.close();
      })
    },
  }
}
</script>

<style scoped>

</style>