<template>
  <div class="column is-12 has-background-white is-multiline">
    <div class="columns is-12 column is-multiline">
      <div class="column is-12">
        <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 16px">
          <b-tooltip label="Guardar">
            <b-button icon-left="save" type="is-primary"
                      @click="check"
                      :disabled="dataStressTest.has_sign"></b-button>
          </b-tooltip>
          <b-tooltip label="Ver PDF">
            <b-button icon-left="eye" type="is-primary" tag="a"
                      :disabled="!dataStressTest.has_sign"
                      :href="pdfUrl"
                      target="_blank"></b-button>
          </b-tooltip>
          <b-checkbox v-model="dataStressTest.has_sign" @input="handleInputSign">Firma</b-checkbox>
        </div>
      </div>
      <div class="column is-6">
        <b-field label="Doctor"
                 label-position="on-border">
          <b-input v-model="dataStressTest.medical" required
                   validation-message="Es necesario llenar este campo" type="text"
                   maxlength="20"></b-input>
        </b-field>
      </div>
      <div class="column is-6">
        <b-field label="Propósito"
                 label-position="on-border">
          <b-input v-model="dataStressTest.purpose" required
                   validation-message="Es necesario llenar este campo" type="text"
                   maxlength="30"></b-input>
        </b-field>
      </div>
      <div class="column is-12">
        <b-field label="Antecedentes"
                 label-position="on-border">
          <b-input v-model="dataStressTest.history" required
                   validation-message="Es necesario llenar este campo" type="text"
                   maxlength="60000"></b-input>
        </b-field>
      </div>
      <div class="column is-12">
        <b-field label="Medicamentos"
                 label-position="on-border">
          <b-input v-model="dataStressTest.medicaments" required
                   validation-message="Es necesario llenar este campo" type="text"
                   maxlength="50"></b-input>
        </b-field>
      </div>
      <div class="column is-4">
        <b-field label="T.A. en Reposo mmHg"
                 label-position="on-border">
          <b-input v-model="dataStressTest.ta" required
                   validation-message="Es necesario llenar este campo" type="text"
                   maxlength="7"></b-input>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="F.C. xMIN"
                 label-position="on-border">
          <b-input v-model="dataStressTest.fc" type="number"
                   min="0" max="999"></b-input>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="FC Máx Esperada"
                 label-position="on-border">
          <b-input v-model="dataStressTest.fc_esp"  type="number"
                   min="0" max="999"></b-input>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="FC Alcanzada"
                 label-position="on-border">
          <b-input v-model="dataStressTest.fc_alc" type="number"
                   min="0" max="999"></b-input>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="%"
                 label-position="on-border">
          <b-input v-model="dataStressTest.percent" type="number"
                   min="0" max="999"></b-input>
        </b-field>
      </div>
      <div class="column is-12">
        <b-field label="EKG en Reposo"
                 label-position="on-border">
          <b-input v-model="dataStressTest.ekg" required
                   validation-message="Es necesario llenar este campo" type="text"
                   maxlength="250"></b-input>
        </b-field>
      </div>
      <div class="column is-6">
        <b-field label="Protocolo"
                 label-position="on-border">
          <b-input v-model="dataStressTest.protocol" required
                   validation-message="Es necesario llenar este campo" type="text"
                   maxlength="18"></b-input>
        </b-field>
      </div>
      <div class="column is-6">
        <b-field label="Motivo p/Suspender la prueba"
                 label-position="on-border">
          <b-input v-model="dataStressTest.reason_to_suspend_test" required
          validation-message="Es necesario llenar este campo" type="text"
          maxlength="30"></b-input>
        </b-field>
      </div>
      <div class="column is-6">
        <b-field label="Realizó"
                 label-position="on-border">
          <b-input v-model="dataStressTest.made_by" required
          validation-message="Es necesario llenar este campo" type="text"
          maxlength="20"></b-input>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Saturación de oxígeno"
                 label-position="on-border">
          <b-input v-model="dataStressTest.spo2" type="number"
          min="0" max="255"></b-input>
        </b-field>
      </div>

      <div class="column is-2">
        <b-field label="Doble producto"
                 label-position="on-border">
          <b-input v-model="dataStressTest.double_product" type="number"
          min="0" max="999999"></b-input>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="METS"
                 label-position="on-border">
          <b-input v-model="dataStressTest.mets" type="number"
          min="0.0" step=".1" max="99"></b-input>
        </b-field>
      </div>
      <div class="column is-6">
        <b-button type="is-primary" expanded @click="dataStressTest.stressTestEffort.push({})">Agregar</b-button>
      </div>

      <div class="column is-12">
        <b-table
            striped
            bordered
            narrowed
            sticky-header
            :data="dataStressTest.stressTestEffort"
            :editable="true"
            height="100%"
        >

          <b-table-column v-slot="props" field="step" label="ETAPA">
            <b-input v-model="props.row.step" @input="validateStepEffort" type="text" maxlength="3"/>
          </b-table-column>
          <b-table-column v-slot="props" field="minutes" label="MINUTOS">
            <b-input v-model="props.row.minutes" type="number" step="1" min="0" max="99" />
          </b-table-column>
          <b-table-column v-slot="props" field="miles" label="MILLAS/HORAS">
            <b-input v-model="props.row.miles" type="number" step="0.1" min="0" max="99.9" style="width: 100px;" />
          </b-table-column>
          <b-table-column v-slot="props" field="incline" label="INCLINACION">
            <b-input v-model="props.row.incline" type="number" step="1" min="0" max="99" />
          </b-table-column>
          <b-table-column v-slot="props" field="heart_rate" label="F.C.">
            <b-input v-model="props.row.fc" type="number" step="1" min="0" max="999" style="width: 100px;" />
          </b-table-column>
          <b-table-column v-slot="props" field="arterial_tension" label="T.A.">
            <b-input v-model="props.row.ta" type="text" maxlength="7" style="width: 100px" />
          </b-table-column>
          <b-table-column v-slot="props" field="observations" label="OBSERVACIONES">
            <b-input v-model="props.row.observations" type="text" maxlength="10"/>
          </b-table-column>
          <b-table-column v-slot="props" label="ACCIONES">
            <b-button @click="handleDropEffort(props.index)" icon-left="trash" type="is-danger"></b-button>
          </b-table-column>


          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                      icon="box-open"
                      size="is-large">
                  </b-icon>
                </p>
                <p>Sin datos</p>
              </div>
            </section>
          </template>

        </b-table>
      </div>

      <div class="column is-6">
        <b-button type="is-primary" expanded @click="dataStressTest.stressTestRecovery.push({})">Agregar</b-button>
      </div>
      <div class="column is-12">
        <b-table
            striped
            bordered
            narrowed
            sticky-header
            :data="dataStressTest.stressTestRecovery"
            :editable="true"
            height="100%"
        >
          <b-table-column v-slot="props" field="minutes" label="MINUTOS">
            <b-input v-model="props.row.minutes" @input="validateMinuteRecovery" type="number" step="1"/>
          </b-table-column>
          <b-table-column v-slot="props" field="heart_rate" label="F.C.">
            <b-input v-model="props.row.fc" type="number" step="1" min="0" max="32757"/>
          </b-table-column>
          <b-table-column v-slot="props" field="arterial_tension" label="T.A.">
            <b-input v-model="props.row.ta" type="text" maxlength="7"/>
          </b-table-column>
          <b-table-column v-slot="props" field="observations" label="OBSERVACIONES">
            <b-input v-model="props.row.observations" type="text" maxlength="10"/>
          </b-table-column>
          <b-table-column v-slot="props" label="ACCIONES">
            <b-button @click="handleDropRecovery(props.index)" icon-left="trash" type="is-danger"></b-button>
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                      icon="box-open"
                      size="is-large">
                  </b-icon>
                </p>
                <p>Sin datos</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
      <div class="column is-6">
        <b-field label="DIAGNÓSTICO">
          <b-input
              maxlength="200" type="textarea" v-model="dataStressTest.diagnostic"/>
        </b-field>
      </div>
      <div class="column is-6">
        <b-field label="RECOMENDACIONES">
          <b-input
              maxlength="200" type="textarea" v-model="dataStressTest.recommendations"/>
        </b-field>
      </div>
    </div>
    <b-modal v-model="showModal">
      <sign-modal @capture="handleCapture" @fail="dataStressTest.has_sign=false"
                  @canceled="dataStressTest.has_sign=false"></sign-modal>
    </b-modal>
  </div>
</template>

<script>
import SignModal from "@/components/medical-specialties/SignModal";

export default {
  name: "EffortTest",
  components: {
    SignModal
  },
  data() {
    return {
      showModal: false,
      errors: [],
    }
  },
  props: {
    dataStressTest: Object,
    order: Object,
  },
  computed: {
    pdfUrl() {
      if (this.dataStressTest && this.dataStressTest.showPdfUrl && this.order && this.order.ordenNew) {
        if (this.dataStressTest.showPdfUrl.toString().indexOf(this.order.ordenNew) > -1) {
          return this.dataStressTest.showPdfUrl;
        }
        return this.dataStressTest.showPdfUrl + this.order.ordenNew;
      }
      return '';
    }
  },

  methods: {
    check() {
      if (this.validateStepEffort() && this.validateMinuteRecovery()) {
        this.onSave();
      }
    },
    onSave() {
      const dataStressTest = this.dataStressTest;
      this.loading = true;
      this.$buefy.dialog.confirm({
        title: 'Guardar interpretación',
        message: '¿Estás seguro de guardar los datos?',
        confirmText: 'Si, guardar',
        cancelText: 'No, cancelar',
        onConfirm: () => {
          this.$loader.show();
          this.$http.post(`/medical-specialties/update-stress-test/${this.order.ordenNew}`, {
            medical: dataStressTest.medical,
            purpose: dataStressTest.purpose,
            history: dataStressTest.history,
            medicaments: dataStressTest.medicaments,
            ta: dataStressTest.ta,
            fc: dataStressTest.fc,
            fc_esp: dataStressTest.fc_esp,
            fc_alc: dataStressTest.fc_alc,
            percent: dataStressTest.percent,
            ekg: dataStressTest.ekg,
            protocol: dataStressTest.protocol,
            reason_to_suspend_test: dataStressTest.reason_to_suspend_test,
            double_product: dataStressTest.double_product,
            diagnostic: dataStressTest.diagnostic,
            recommendations: dataStressTest.recommendations,
            mets: dataStressTest.mets,
            spo2: dataStressTest.spo2,
            made_by: dataStressTest.made_by,
            efforts: dataStressTest.stressTestEffort,
            recoveries: dataStressTest.stressTestRecovery,
          }).then((data) => {
            //console.log(data);
            this.$toast.success('Datos guardados correctamente');
          }).catch(errors => {
            this.$alfalab.showError(errors, true);
          }).finally(() => {
            this.$loader.hide();
          })
        }
      })
    },
    handleCapture(result) {
      this.$loader.show();
      this.$http.post(`/medical-specialties/sign-stress-test/${this.order.ordenNew}`, {
        sign: result.sign
      }).then(() => {
        this.dataStressTest.has_sign = true;
        this.$buefy.dialog.alert("Firmado con éxito");
      }).catch(() => {
        this.dataStressTest.has_sign = false;
      }).finally(() => {
        this.$loader.hide();
        this.showModal = false;
      })
    },
    handleInputSign(value) {
      //console.log(value);
      if (value) {
        this.showModal = true;
      } else {
        this.$buefy.dialog.confirm({
          title: 'Quitar Firma',
          message: '¿Seguro que quiere quitar la firma?',
          onConfirm: () => {
            this.$loader.show();
            this.$http.post(`/medical-specialties/sign-stress-test/${this.order.ordenNew}?remove=1`).then(() => {
              this.dataStressTest.has_sign = false;
            }).finally(() => {
              this.$loader.hide();
            });
          },
          onCancel: () => {
            this.dataStressTest.has_sign = true;
          }
        })
      }
    },
    handleDropEffort(index) {
      this.dataStressTest.stressTestEffort.splice(index, 1)
    },
    handleDropRecovery(index) {
      this.dataStressTest.stressTestRecovery.splice(index, 1)
    },
    validateStepEffort() {
      // Obtener los elementos de la columna "step"
      const elementosColumna = this.dataStressTest.stressTestEffort.map(item => item.step);
     // console.log("Elementos de la columna:", elementosColumna);
      const elementosRepetidos = {};
      this.dataStressTest.stressTestEffort.forEach(item => {
        if (elementosRepetidos[item.step]) {
          elementosRepetidos[item.step]++;
        } else {
          elementosRepetidos[item.step] = 1;
        }
      });
      for (const step in elementosRepetidos) {
        if (elementosRepetidos[step] > 1) {
         // console.log(`El elemento "${step}" se repite ${elementosRepetidos[step]} veces.`);
          this.$buefy.dialog.alert({
            title: 'Etapa no permitida',
            message: `La etapa "${step} " se repite, por favor ingrese otro valor.`
          })
          return false;
        }
      }
      return true;
    },
    validateMinuteRecovery() {
      const elementosColumna = this.dataStressTest.stressTestRecovery.map(item => item.minutes);
     // console.log("Elementos de la columna en recovery:", elementosColumna);
      const elementosRepetidos = {};
      this.dataStressTest.stressTestRecovery.forEach(item => {
        if (elementosRepetidos[item.minutes]) {
          elementosRepetidos[item.minutes]++;
        } else {
          elementosRepetidos[item.minutes] = 1;
        }
      });
      for (const minutes in elementosRepetidos) {
        if (elementosRepetidos[minutes] > 1) {
         // console.log(`El elemento "${minutes}" se repite ${elementosRepetidos[minutes]} veces.`);
          this.$buefy.dialog.alert({
            title: 'Minuto no permitido',
            message: `El minuto "${minutes} " se repite, por favor ingrese otro valor.`
          })
          return false;
        }
      }
      return true;
    },
  }
}
</script>

<style scoped>

</style>