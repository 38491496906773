<template>
<div class="table-container lapi__log">
  <table class="table is-bordered is-striped is-narrow is-hoverable">
    <thead>
    <tr>
      <th>OrdenNew</th>
      <th>Usuario</th>
      <th>Fecha</th>
      <th>Forma</th>
      <th>Metodo</th>
      <th>Mensaje</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in data">
      <td>{{item.order_new}}</td>
      <td>{{item.user}}</td>
      <td>{{item.date}}</td>
      <td>{{item.form}}</td>
      <td>{{item.method}}</td>
      <td>
        <a :href="item.message" v-if="item.message.toString().startsWith('http')" target="_blank">{{item.message}}</a>
        <span v-else>{{item.message}}</span>
      </td>
    </tr>
    </tbody>
  </table>
</div>
</template>

<script>
export default {
  name: "OrderHistory",
  props: {
    order: {}
  },
  data() {
    return {
      data: [],
      apiUrl: 'lapi-log'
    }
  },
  mounted() {
    if(this.order.id){
      this.prepareData();
    }
  },
  methods: {
    prepareData() {
      this.getAll({
        order: this.order.order_new
      }).then(({data}) => {
        this.data = data;
      }).catch(() => {

      }).finally(() => {

      })
    }
  }
}
</script>

<style scoped>
.lapi__log{
  height: 300px;
  overflow: auto;
}
</style>
